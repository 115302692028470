
.result-card-wrapper {

    &:not(.active) {
        .result-card {
            &:hover {
                transition: transform .3s;
                transform: scale(1.02);
            }
        }
    }

    .result-card
    {
        cursor: pointer;

        padding: 20px 15px;
        border-radius: 5px;
        margin: 5px;
        background-color: #292929;
        color: #d9d9d9;

        -webkit-box-shadow: 0px 0px 48px -15px rgba(0,0,0,1);
        -moz-box-shadow: 0px 0px 48px -15px rgba(0,0,0,1);
        box-shadow: 0px 0px 48px -15px rgba(0,0,0,1);

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

        img, p {
            display: inline-block;
            margin: 0;
        }

        p {
            line-height: 2em;
        }

        .result-name {
            text-transform: capitalize;
        }

        .result-value {
            float: right;
        }

        img {
            height: 2em;
            margin-right: 10px;
        }

        .expanded-content {
            margin-top: 10px;
        }



    }
}