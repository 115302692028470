@import "./scss/variables.scss";
@import "~bootstrap/scss/bootstrap";

select {
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

* {
  box-sizing: border-box;
  color: $text_colour;
}

body {
  background-color: $background;
  font-family: sans-serif;
}


div.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  columns: 1;
  min-height: 60vh;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px
}

.logo img {
  max-width: 100%;
}

.results-list {
  width: 100%;
  margin-top: 10px;
}

#currencyAmount, #currencyType {
  height: 45px;
  background-color: #282828;
  color: #d9d9d9;
  padding: 3px 20px;
  border: 0;
  border-radius: 22px;
  margin-top: 10px;
}

#currencyAmount {
  margin-right: 5px;
}

#go, #share, #sort, #copy {
  margin: 15px auto 15px;
  height: 45px;
  padding: 10px 25px;
  background-color: $colour_primary;
  color: $background;
  border: 0;
  border-radius: 22px;
  font-weight: 900;
  text-transform: uppercase;
  display: block;
}

#timeUpdated {
  margin: 15px auto 15px;
  height: 45px;
  line-height: 45px;
  display: block;
  width: fit-content;
}

@media screen and (min-width: 576px) {
  #sort {
    float: right;
  }

  #timeUpdated {
    float: left;
  }
}

#input-container {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: $card_background;
  -webkit-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
}

#share-container {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 5px;
  text-align: center;
  background-color: $card_background;
  -webkit-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 40px -15px rgba(0,0,0,0.7);
}

.disclaimer {
  font-size: 11px;
}